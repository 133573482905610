<template>
    <div class="background-window" @click.self="closeWindow">
        <div class="window-error" id="windowError" ref="windowError">
            <div class="head-window">
                <h2 class="name-window-param middle-high">Журнал ошибок ({{name}})</h2>
                <button @click="closeWindow">
                    <img src="@/assets/imgs/crosser-black.svg" alt="">
                </button>
            </div>
            <div class="contral-line">
                <div class="el-form" name-line="Период:">
                    <el-date-picker   
                        v-model="date"
                        type="datetimerange"
                        start-placeholder="Начальная дата"
                        end-placeholder="Конечная дата"
                        size="small"
                        format="DD/MM/YYYY HH:mm"
                        popper-class="datepicker-custom"
                    />
                </div>
                <button class="button-load-data" @click="getData">Посмотреть</button>
            </div>
            <div class="content">
                <el-table :data="tableData" :default-sort="sortingParam" border :height="highTable" style="width: 100%" v-if="!loadDataFlag">
                    <el-table-column prop="date" sortable label="Дата" :width="widthFirstColumn" />
                    <el-table-column prop="code" label="Код" :width="widthSecondColumn" />
                    <el-table-column prop="name" label="Название" />
                </el-table>
                <div v-if="loadDataFlag" style="display: flex; justify-content: center;">
                    <span class="loader"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from '../../../config-axios'

export default {
    name: "WindowError",
    props: ['id', 'name', 'mac', 'errorBase'],
    emits: ['closeWindow'],
    computed: {
        highTable() {
            return (window.innerHeight)*0.75
        },
        widthFirstColumn() {
            const width = (window.innerWidth)*0.3
            if (width > 170) {
                return 170
            }
            return width
        },
        widthSecondColumn() {
            return 100
        }
    },
    data() {
        return {
            loadDataFlag: false,
            date: [],
            sortingParam: {
                prop: 'date',
                order: 'descending'
                // order: 'ascending'
            },
            tableData: [
            ]
        }
    },
    methods: {
        closeWindow() {
            this.$emit('closeWindow')
        },
        getData() {
            let options = {
                id: this.id,
                start_time: this.date[0].toLocaleString('en-GB', { timeZone: 'Europe/Minsk' }),
                end_time: this.date[1].toLocaleString('en-GB', { timeZone: 'Europe/Minsk' }),
            }
            console.log(options)
            this.loadDataFlag = true
            axios.post('compressor/error/', options).then(response=>{
                this.tableData = response.data.map(el=>{
                    let error = this.errorBase.find(error => { if (error.code == el.code) return true })
                    console.log(this.errorBase)
                    return {
                        date: el.date.slice(0, 10) + ' ' + el.date.slice(11, 19),
                        code: el.code,
                        name: error?error.description:"-"
                    }
                })
                this.sortingParam.order = 'ascending'
            }).finally(() => {
                this.loadDataFlag = false
            })
        },
        getToDayData() {
            let options = {
                id: this.id,
                start_time: new Date().toLocaleString('en-GB', { timeZone: 'Europe/Minsk' }).slice(0, 10) + ", 00:00:00",
                end_time: new Date().toLocaleString('en-GB', { timeZone: 'Europe/Minsk' }).slice(0, 10) + ", 23:59:59",
            }
            this.loadDataFlag = true
            axios.post('compressor/error/', options).then(response=>{
                this.tableData = response.data.map(el=>{
                    console.log(el)
                    let error = this.errorBase.find(error => { if (error.code == el.code) return true })
                    return {
                        date: el.date.slice(0, 10) + ' ' + el.date.slice(11, 19),
                        code: el.code,
                        name: error?error.description:"-"
                    }
                })
                this.sortingParam.order = 'ascending'
            }).finally(() => {
                this.loadDataFlag = false
            })
        }
    },
    created() {
        // this.getToDayData()
    }
}
</script>
<style noscoped lang="scss">
.background-window{
    position: absolute;
    z-index: 2;
    top:0;
    left:0;
    background: rgba(0, 0, 0, 0.295);
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

}

.window-error{
    background: var(--background-white, #FCFCFC);
    width: 57vw;
    height: 90%;
    cursor: auto;
    border-radius: 4px;
    padding: 12px 9px;
    display: flex;
    flex-direction: column;

    .head-window {
        display: flex;
        justify-content: space-between;

        button {
            padding: 1px 2px;
            background: none;
            cursor: pointer;
        }
    }

    .contral-line {
        margin: 25px;
        display: flex;
        justify-content: space-between;

        .el-form {
            position: relative;
        }
        .el-form::before {
            content: attr(name-line);
            position: absolute;
            bottom: 110%;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .button-load-data {
            cursor: pointer;
            display: flex;
            width: 200px;
            height: 23px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: var(--background-blue, #3F51B5);
            color: var(--background-white, #FCFCFC);
            transition: all .2s ease;
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            
        
            &:hover {
                box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
            }
            &:active {
                box-shadow: none;
            }
        }
    }
    .content {
        overflow: auto;
    }
}
.loader {
  width: 48px;
  height: 48px;
  background: var(--background-blue, #3F51B5);
  display: inline-block;
  border-radius: 50%;
  box-sizing: border-box;
  animation: animloader 1s ease-in infinite;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
} 
@media screen and (max-width: 600px) {
    .window-error{
        width: 90vw;
        height: 95vh;

        .contral-line {
            margin: 25px 5px 0 5px;
            display: flex;
            flex-direction: column;

            .button-load-data {
                margin: 10px 0;
                width: 100%;
            }
        }
        
    }
    
}
</style>