<template>
    <div class="main-page-body">
        <HighBoard
            @logOut="logOutClick"
            @clickCompany="openPageCompany"
            @clickDashboard="openPageDashboard"
            @clickModem="openPageModem(null)"
            :userData="userOptions"
            :choosenPage="choosenPage"
        />
    
        <Transition name="fade" mode="out-in">
            <component
                :is="activeComponent"
                :startModemId="startChoosedModemId"
                @goToModemPage="redirectToModem"
            />
        </Transition>
        <Transition name="fade" >
            <div class="agreement-notification-place" v-show="showAgreement">
                <div class="agreement-window">
                    <h2>Соглашение</h2>
                    <p>Ваша электронная почта *: 
                                <el-input
                                    v-model="emailField"
                                    style="
                                        width: 300px;
                                        margin: 10px 0px;
                                    "
                                    label="Элуктронная почта"
                                    type="email"
                                    placeholder="Пожалуйста введите электронную почту"
                                    @blur="validateEmail"
                                />
                    </p>
                    <!-- </el-input> -->
                    <div class="agreement-pdf-place">
                        <PDF src="agreement_v1.pdf" pdfWidth="100%" />
                    </div>
                    
                    <el-popover
                        trigger="hover"
                        placement="right"
                        :visible="!emailStatusField.status?null:false"
                        title=""
                        :width="250"
                        :content="emailStatusField.text"
                    >
                        <template #reference>
                            <el-button type="primary" :disabled="!emailStatusField.status" @click="acceptAgreement">Я согласен</el-button>
                        </template>
                    </el-popover>
                </div>
            </div>
        </Transition>
    </div>
</template>
<script>
import axios from '../../config-axios'
import { shallowRef } from 'vue'
import PDF from "pdf-vue3";
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { ElMessage } from 'element-plus'
// import CompressorWidget from '@/components/Simple_elements/CompressorWidget.vue'
// import WindowGraph from '@/components/Simple_elements/WindowGraph.vue'
// import WindowParam from '@/components/Simple_elements/WindowParam.vue'
// import WindowError from '@/components/Simple_elements/WindowError.vue'
import HighBoard from '@/components/Simple_elements/HighBoard.vue'
// import MyCollapse from '@/components/Simple_elements/MyCollapse.vue'
import DashboardVue from './Simple_elements/DashboardPage.vue'
import CompanyVue from './Simple_elements/CompanyPage.vue'
import ModemPageVue from './Simple_elements/ModemPage.vue'

export default {
    name: 'MainWindow',
    computed: {
        ...mapGetters({
            userOptions: 'getUserOptions',
        }),
        emailStatusField() {
            if (this.emailField=='') return { text: 'Введите электронную почту.', status: false }
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailField)) {
                return {
                    status: true,
                    text: ''
                }
            } else {
                return {
                    text: this.emailField+' не является действительным адресом электронной почты.',
                    status: false
                }
            }
        }
    },
    data() {
        return {
            activeComponent: shallowRef(DashboardVue),
            startChoosedModemId: null,
            choosenPage: 'dashboard',
            showAgreement: true,
            emailField: '',
            flagEmailPopup: false,
            popupText: ''
        }
    },
    components: {
        HighBoard,
        DashboardVue,
        CompanyVue,
        ModemPageVue,
        PDF
        // pdf
    },
    methods: {
        ...mapActions({
            postLogout: 'postLogout',
            getCompressors: 'getCompressors',
            getStatusDescriptions: 'getStatusDescriptions',
        }),
        ...mapMutations({
            mutationUser: 'setUserParam'
        }),
        logOutClick() {
            this.startChoosedModemId = null;
            this.choosenPage = 'dashboard';
            this.postLogout();
        },
        openPageCompany() {
            this.startChoosedModemId = null;
            this.choosenPage = 'company';
            this.activeComponent = shallowRef(CompanyVue);
            
        },
        openPageDashboard() {
            this.startChoosedModemId = null;
            this.choosenPage = 'dashboard';
            this.activeComponent = shallowRef(DashboardVue);
            
        },
        openPageModem(id) {
            if (id) {
                this.startChoosedModemId = id;
            } else {
                this.startChoosedModemId = null;
            }
            this.choosenPage = 'modem';
            this.activeComponent = shallowRef(ModemPageVue);
        },
        redirectToModem(id) {
            this.openPageModem(id);
        },
        acceptAgreement() {
            axios.put('user/agreement/', { email: this.emailField }).then(response => {
                this.mutationUser(response.data)
                this.checkUser()
            }).catch(err => {
                ElMessage.error('Ошибка сервера')
            })
        },
        validateEmail() {
        },
        checkUser() {
            if (!this.userOptions.agreement) {
                this.showAgreement = true;
            } else {
                this.showAgreement = false
            }
        }
    },
    created() {
        this.checkUser()
    },
    beforeUnmount() {
    }
}

</script>
<style noscoped lang="scss">
// .high-board{
//     width: 100vw;
//     background: #3F51B5;
//     height: 7vh;
//     box-shadow: 3px 13px 8px 0px rgba(34, 60, 80, 0.2);
//     display: flex;
//     align-items: center;
    
//     .logout{
//         cursor: pointer;
//         margin: 0px 1vw;
//         height: 50%;
//         background: #fff;
//         padding: 4px 10px;
//         border-radius: 4px;
//         i{
//             margin: 0px 6px;
//             color:#3f51b5;
//         }
//     }
// }
// .solo-company {
//     overflow: hidden;
//     transition: all .2s ease;
//     padding: 24px 30px;
//     display: grid;
//     grid-template-columns: repeat(3, 1fr);
//     gap: 10px;
// }
.main-page-body {
    position: relative;
    
    .agreement-notification-place {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.752);
        display: flex;
        justify-content: center;
        align-items: center;

        .agreement-window {
            background: var(--background-white);
            width: 90vw;
            height: 90vh;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            // align-items: center;
            padding: 5% 20%;

            .agreement-pdf-place {
                // width: 100%;
                height: 100%;
                margin-top: 20px;
            }
        }
    }
}
.main-dask{
    height: 93vh;//issue: should be 93vh
    // display: flex;
    // align-items: flex-start;
    // justify-content: flex-start;
    // flex-direction: row;
    // flex-wrap: wrap;
    // align-content: flex-start;
    padding: 10px 1vw;
    overflow-y: auto;
}

</style>



