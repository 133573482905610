<template>
  <Transition name="fade" mode="out-in">
    <component :is="activeComponent"></component>
  </Transition>
</template>

<script>
import LoginWindow from './components/LoginWindow.vue'
import MainWindow from './components/MainWindow.vue'
import TestDashboard from './components/TestPage/TestDashboard.vue'
import { shallowRef } from 'vue'
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'App',
  data() {
    return {
      status: true,
      activeComponent: shallowRef(LoginWindow)
    }
  },
  components: {
    LoginWindow,
    MainWindow,
    TestDashboard
  },
  watch: {
    'userOptions.loginStatus'(newStatus) {
      if (newStatus) {
        this.activeComponent = shallowRef(MainWindow);
      } else {
        this.activeComponent = shallowRef(LoginWindow);
      }
    }
  },
  computed: {
    ...mapGetters({
      userOptions: 'getUserOptions'
    })
  },
  methods: {
    ...mapActions({
      checkUserInfo: 'checkUserInfo'
    })
  },
  created() {
    document.title = 'remeza | Мониторинг'
    this.checkUserInfo()
  }
}
</script>

<style lang="scss">
// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');
*{
    margin: 0;
    padding: 0;
    outline: 0;
    appearance: 0;
    border: 0;
    text-decoration: none;
    box-sizing: border-box;
}
html{
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}
@font-face {
  font-family: 'Lato';
  src: url('@/assets/font/Lato2/Lato-Regular.ttf');
}
:root {
  --background-white: #FCFCFCFF;
  --background-blue: #3F51B5FF;
  --gray-color: #EDEDEDFF;
  --green-color: #B3DDBEFF;
  --red-color: #F97676FF;
  --gray-dark-color: #949494FF;
  --black-color: #000000FF;
}

/* Text Styles  */
.plain-text {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0%;
}

.middle-high {
  font-family: Lato;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0%;
}

.the-biggest {
  font-family: Lato;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0%;
}

.small-text {
  font-family: Lato;
  font-size: 9px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0%;
}

.back-text {
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0%;
}


body {
    width: 100vw;
    height: 100vh;
    user-select: none;
    overflow-x: hidden;
    background: var(--background-white);
    color: var(--color-dark);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
