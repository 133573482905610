<template>
    <div class="notification-window">
        <div class="control-part">
            <button class="plain-text" @click="openAdditional">
                Открыть дополнительные параметры
            </button>
        </div>
        <!-- <div class="notification-form" v-if="showAdditionalPart"> -->
            <!-- <ul>
                <li>Telegram: <button @click="takeSecretNumber">Привязать</button></li>
                <li></li>
            </ul> -->
            <el-space direction="vertical" v-if="showAdditionalPart">
                <el-card class="box-card" style="width: 320px">
                <template #header>
                    <div class="card-header">
                        <h3>Telegram <el-button class="button" @click="takeSecretNumber" text>{{nameTelegramButton}}</el-button></h3>
                    </div>
                </template>
                <div class="text item" v-if="secretNumber">
                    <ul>
                        <li class="back-text">Зайдите в Telegram и найдите бот "spamer".</li>
                        <li class="back-text">Запустите его</li>
                        <li class="back-text">Отправьте временный личный код.</li>
                        <p class="back-text">Временный личный код: <el-button class="plain-text" @click="chainingTelegram(secretNumber)" plain>{{ secretNumber }}</el-button></p>
                    </ul>
                    <!-- <p>Зайдите в Telegram и найдите бот "spamer". Запустите его и отправьте временный личный код.</p>
                    <p>Временный личный код <el-button @click="chainingTelegram(secretNumber)" plain>{{ secretNumber }}</el-button></p> -->
                </div>
                </el-card>
            </el-space>

        <!-- </div> -->
    </div>
</template>
<script>
import { ElMessage } from 'element-plus'
import axios from '../../../config-axios'
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
    name: 'WindowNotification',
    data() {
        return {
            showAdditionalPart: false,
            secretNumber: null,
            telegramFlag: false
        }
    },
    props: [],
    emits: [],
    computed: {
        ...mapGetters({
            userOptions: 'getUserOptions',
        }),
        nameTelegramButton() {
            if (this.userOptions.notification.telegram) {
                return 'Отвязать'
            } else {
                return 'Привязать'
            }
        },
    },
    methods: {
        ...mapMutations({
            setNotificationTelegram: 'setNotificationTelegram'
        }),
        openAdditional() {
            this.showAdditionalPart = !this.showAdditionalPart
        },
        chainingTelegram(secretNumber) {
            navigator.clipboard.writeText(secretNumber);
            ElMessage({
                message: 'Скопировано!',
                type: 'success',
            });
        },
        takeSecretNumber() {
            if (this.userOptions.notification.telegram) {
                axios.delete('telegram_bot/take_secret_key/').then(response => {
                    if(response.data.status) {
                        this.setNotificationTelegram(!response.data.status)
                    }
                })
            } else {
                axios.get('telegram_bot/take_secret_key/').then(response => {
                    this.secretNumber = response.data
                })
            }
        },
    },
    created() {
    }
}
</script>
<style noscoped lang="scss">
    .notification-window {
        .notification-form {
            border-radius: 4px;
            padding: 25px 30px;
            background: rgba(0, 0, 0, 0.078);

            button {
                cursor: pointer;
            }
        }

        .control-part {
            margin-bottom: 10px;

            button {
                cursor: pointer;
                border-radius: 4px;
                padding: 5px 10px;
                background: none;
                transition: all .2s ease;

                &:hover {
                    background: rgba(0, 0, 0, 0.078);
                }
            }
        }
    }
</style>