import { createStore } from 'vuex'
import { ElMessage } from 'element-plus'
import axios from '../../config-axios'

export default createStore({
  state: {
    userOptions: {
      loginStatus: false,
      username: null,
      role: 'guest'
    },
    windowLogin: {
      loaderButton: false
    },
    compressorsList: {},
    statusDescriptions: {},
    companyList: [],
    dealerList: [],
    dealerListByCompanyPage: [],
    userListByCompanyPage: [],
    modemList: [],
    modemPageRightPart: {
      loadingFlag: false,
      compressors: []
    }
  },
  getters: {
    getUserOptions(state) {
      return state.userOptions
    },
    getLoaderLoginButton(state) {
      return state.windowLogin.loaderButton
    },
    getCompressorsList(state) {
      return state.compressorsList
    },
    getStatusDescriptions(state) {
      return state.statusDescriptions
    },
    getCompanyList(state) {
      return state.companyList
    },
    getDealerList(state) {
      return state.dealerList
    },
    getDealerListByCompanyPage(state) {
      return state.dealerListByCompanyPage
    },
    getUserListByCompanyPage(state) {
      return state.userListByCompanyPage
    },
    getModemList(state) {
      return state.modemList
    },
    getModemPageRightPartData(state) {
      return state.modemPageRightPart
    }
  },
  mutations: {
    setLogin(state, options) {
      state.userOptions.loginStatus = options
    },
    setLoaderButtonLogin(state, options) {
      state.windowLogin.loaderButton = options
    },
    setUserParam(state, options) {
      state.userOptions.loginStatus = true
      state.userOptions.username = options.username
      state.userOptions.role = options.role
      state.userOptions.agreement = options.agreement
      if (options.notification) {
        state.userOptions.notification = options.notification
      } else {
        state.userOptions.notification.telegram = false
      }
    },
    setNotificationTelegram(state, options) {
      state.userOptions.notification.telegram = options
    },
    setCompressorsList(state, options) {
      state.compressorsList = options
    },
    setCompressorParam(state, options) {
      
      // let indexCom = null
      let nameProperty = null
      for (const property in state.compressorsList) {
        state.compressorsList[property].compressors = state.compressorsList[property].compressors.filter(el=>{
          if (options.id != el.id) {
            return true
          } else {
            return false
          }
        })
        if (state.compressorsList[property].compressors.length == 0) {
          delete state.compressorsList[property]
        }
        if (state.compressorsList[property]?.company_id == options.company_id) {
          nameProperty = property;
        }
      }
      if (nameProperty != null) {
        state.compressorsList[nameProperty].compressors.push(options);
        state.compressorsList[nameProperty].compressors.sort((a, b) => new Date(a.created_date) - new Date(b.created_date));
      } else {
        state.compressorsList[options.short_name] = {
          company_id: options.company_id,
          showing_name_of_company: options.showing_name,
          showing_name_of_dealer: options.dealer_name,
          compressors: [options]
        }
      }
      
    },
    setStatusDescriptions(state, options) {
      state.statusDescriptions = options
    },
    setCompanyList(state, options) {
      state.companyList = options
      state.companyList.unshift({ id: 0, label: 'Не зарегистрированные' })
    },
    setDealerList(state, options) {
      state.dealerList = options
      state.dealerList.unshift({ id: 0, label: 'Не зарегистрированные' })
    },
    setDealerListByCompanyPage(state, options) {
      state.dealerListByCompanyPage = options
    },
    addDealerToListByCompanyPage(state, options) {
      state.dealerListByCompanyPage.push(options)
    },
    changeDealerToListByCompanyPage(state, options) {
      state.dealerListByCompanyPage = state.dealerListByCompanyPage.map(el=>{
        if (el.id == options.id) {
          return options
        } else {
          return el
        }
      })
    },
    deleteDealerFromDealerList(state, options) {
      state.dealerListByCompanyPage = state.dealerListByCompanyPage.filter(el=>{
        return el.id != options
      })
    },
    addCompanyToListByCompanyPage(state, options) {
      state.dealerListByCompanyPage = state.dealerListByCompanyPage.map(el=>{
        if (el.id == options.dealer_id) {
          el.companies.push(options.data)
          return el
        } else {
          return el
        }
      })
    },
    changeCompanyToListByCompanyPage(state, options) {
      state.dealerListByCompanyPage = state.dealerListByCompanyPage.map(el=>{
        if (el.id == options.dealer_id) {
          el.companies = el.companies.map(company=>{
            if(company.id == options.data.id) {
              return options.data
            } else {
              return company
            }
          })
          return el
        } else {
          return el
        }
      })
    },
    deleteCompanyFromCompanyList(state, options) {
      state.dealerListByCompanyPage = state.dealerListByCompanyPage.map(el=>{
        if (el.companies.filter(company=>{ return company.id == options }).length) {
          el.companies = el.companies.filter(company=>{ return company.id != options })
          return el
        } else {
          return el
        }
      })
    },
    setUserList(state, options) {
      state.userListByCompanyPage = options
    },
    setUserToList(state, options) {
      state.userListByCompanyPage.push(options)
    },
    deleteUserFromList(state, options) {
      state.userListByCompanyPage = state.userListByCompanyPage.filter(el => {
        if (el.id == options) {
          return false
        } else {
          return true
        }
      })
    },
    setModemList(state, options) {
      state.modemList = options
    },
    setModemPageRightPartLoadingFlag(state, options) {
      state.modemPageRightPart.loadingFlag = options;
    },
    setModemPageRightPartCompressorsList(state, options) {
      state.modemPageRightPart.compressors = options;
    },
    setModemPageParamModem(state, options) {
      state.modemList = state.modemList.map(el=>{
        if (el.id == options.id) {
          return options
        } else {
          return el
        }
      })
    }
  },
  actions: {
    async postLogin(context, data){
      context.commit('setLoaderButtonLogin', true)
      axios.defaults.headers.common = {}
      await axios.post('user/login/', data).then( response => {
        context.commit('setLogin', true)
        localStorage.setItem('token', response.data.token)
        axios.defaults.headers.common = {'Authorization': `Token ${response.data.token}`}
        context.commit('setLoaderButtonLogin', false)
        context.commit('setUserParam', response.data)
      }).catch( error => {
        console.log(error)
        ElMessage.error('Неверные параметры для входа')
        context.commit('setLoaderButtonLogin', false)
      })
    },
    async postLogout(context) {
      axios.defaults.headers.common = {}
      localStorage.setItem('token', '')
      context.commit('setLogin', false)
    },
    async checkUserInfo(context) {
      await axios.get('user/info/').then( (response) => {
        context.commit('setUserParam', response.data)
      }).catch( error => {
        console.log(error)
      })
    },
    async getCompressors(context) {
      await axios.get('compressor_point/').then( response => {
          context.commit('setCompressorsList', response.data)
      }).catch(error=>{
        console.log(error)
        // alert('Error request')
        ElMessage.error('Ошибка, не получилось получить данные о оборудовании')
      })
    },
    async getApiCompanyList(context) {
      await axios.get('company/').then(response => {
        context.commit('setCompanyList', response.data)
      }).catch(error=>{
        console.log(error)
        ElMessage.error('Ошибка, не удалось получить список компаний')
      })
    },
    async getApiDealerList(context) {
      await axios.get('dealer/').then(response => {
        context.commit('setDealerList', response.data)
      }).catch(error=>{
        console.log(error)
        ElMessage.error('Ошибка, не удалось получить список дилеров')
      })
    },
    async putCompressorSettings(context, options) {
      await axios.put('compressor/', options).then(response=>{
        context.commit('setCompressorParam', response.data)
        ElMessage.success('Параметры изменены')
      }).catch(error=>{
        console.log(error)
        // alert('Error request')
        ElMessage.error('Ошибка, не удалось изменить параметры')
      })
    },
    async getStatusDescriptions(context) {
      await axios.get('get_status_descriptions/').then(response => {
        context.commit('setStatusDescriptions', response.data)
      }).catch(error=>{
        console.log(error)
        ElMessage.error('Ошибка, определения статусов')
      })
    },
    async getApiDealerListByCompanyPage(context) {
      await axios.get('user/dealer/').then(response => {
        context.commit('setDealerListByCompanyPage', response.data)
      }).catch(()=> {
        ElMessage.error('Ошибка, не удалось получить список дилеров')
      })
    },
    async postApiDealerListByCompanyPage(context, options) {
      await axios.post('user/dealer/', options).then(response => {
        context.commit('addDealerToListByCompanyPage', response.data)
      }).catch((error)=> {
        if (error.response.data?.non_field_errors) {
          ElMessage.error('Данное короткое имя занято.')
        } else {
          ElMessage.error('Ошибка, не удалось добавить дилера')
        }
      })
    },
    async putApiDealerListByCompanyPage(context, options) {
      await axios.put('user/dealer/'+options.id+'/', options.param).then(response => {
        context.commit('changeDealerToListByCompanyPage', response.data)
      }).catch((error)=> {
        if (error.response.data?.non_field_errors) {
          ElMessage.error('Данное короткое имя занято.')
        } else {
          ElMessage.error('Ошибка, не удалось изменить дилера')
        }
      })
    },
    async deleteApiDealerListByCompanyPage(context, options) {
      await axios.delete('user/dealer/'+options+'/').then(response => {
        context.commit('deleteDealerFromDealerList', options)
      })
    },
    async postApiCompanyListByCompanyPage(context, options) {
      await axios.post('user/company/', options).then(response => {
        context.commit('addCompanyToListByCompanyPage', {
          dealer_id: options.dealer_id,
          data: response.data
        })
      }).catch((error)=> {
        if (error.response.data?.non_field_errors) {
          ElMessage.error('Данное короткое имя занято.')
        } else {
          ElMessage.error('Ошибка, не удалось добавить компанию')
        }
        // ElMessage.error('Ошибка, не удалось добавить дилера')
      })
    },
    async putApiCompanyListByCompanyPage(context, options) {
      await axios.put('user/company/'+options.id+'/', options.param).then(response => {
        context.commit('changeCompanyToListByCompanyPage', {
          data: response.data,
          dealer_id: options.param.dealer_id
        })
      }).catch((error)=> {
        if (error.response.data?.non_field_errors) {
          ElMessage.error('Данное короткое имя занято.')
        } else {
          ElMessage.error('Ошибка, не удалось изменить компанию')
        }
      })
    },
    async deleteApiCompanyListByCompanyPage(context, options) {
      await axios.delete('user/company/'+options+'/').then(response => {
        context.commit('deleteCompanyFromCompanyList', options)
      })
    },
    async getApiUserListByCompanyPage(context, options) {
      await axios.get('user/?type='+options.type+'&id='+options.id).then(response => {
        context.commit('setUserList', response.data)
      }).catch(()=> {
        ElMessage.error('Ошибка, не удалось добавить дилера')
      })
    },
    async postApiUserByCompanyPage(context, options) {
      await axios.post('user/', options).then(response => {
        // console.log(response.data)
        context.commit('setUserToList', response.data)
      }).catch((error)=> {
        if (error?.response.data.non_field_errors[0].substr(error?.response.data.non_field_errors[0].length - 23) == 'must make a unique set.') {
          ElMessage.error('Данный логин уже занят.')
        } else {
          ElMessage.error('Ошибка, не удалось добавить пользователя')
        }
      })
    },
    async deleteApiUserByCompanyPage(context, options) {
      await axios.delete('user/'+options+'/').then(response=>{
        if (response.data.status) {
          context.commit('deleteUserFromList', options)
        }
      }).catch(er => {
        ElMessage.error('Ошибка, не удалось удалить пользователя')
      })
    },
    async putApiUserByCompanyPage(context, options) {
      await axios.put('user/'+options.id+'/', options).then(response=> {
        console.log(response.data)
        ElMessage.success('Пароль изменен')
      }) 
    },
    async getApiModemsList(context) {
      await axios.get('modem/').then(response => {
        // console.log(response.data)
        context.commit('setModemList', response.data)
      }).catch(er => {
        ElMessage.error('Ошибка, не удалось получить список модемов')
      })
    },
    async getApiCompressorsByModem(context, options) {
      context.commit('setModemPageRightPartLoadingFlag', true);
      await axios.get('modem/'+options+'/compressors/').then(response=> {
        // console.log(response.data);
        context.commit('setModemPageRightPartCompressorsList', response.data);
        context.commit('setModemPageRightPartLoadingFlag', false);
      }).catch(er => {
        context.commit('setModemPageRightPartLoadingFlag', false);
        ElMessage.error('Ошибка, не удалось получить список компрессоров');
      })
    },
    async putApiModemParam(context, options) {
      await axios.put('modem/'+options.id+'/', options).then(response=>{
        console.log(response.data)
        context.commit('setModemPageParamModem', response.data)
        ElMessage.success('Параметры изменены')
      }).catch(er => {
        ElMessage.error('Ошибка, не удалось изменить параметры модема');
      })
    }
  },
  modules: {
  }
})
