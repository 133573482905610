<template>
    <div class="main-dask-modem-page">
        <div class="left-part">
            <div class="searching-line">
                <input type="text" placeholder="Поиск..." v-model="searchingLine">
            </div>
            <div v-for="modem in workingListModem"
                :key="modem.id"
                @click="showPropertyModem(modem)"
                class="modem-line"
                :class="{
                    choosed: modem.id == rightPartData.modemsData.id
                }"
            >
                <div class="data-part" v-if="modem.name==''">
                    <h2 class="name-company plain-text">{{modem.mac_address}}</h2>
                    <p class="number-us small-text">{{createName(modem.name)}}</p>
                </div>
                <div class="data-part" v-if="modem.name!=''">
                    <h2 class="name-company plain-text">{{createName(modem.name)}}</h2>
                    <p class="number-us small-text">{{modem.mac_address}}</p>
                </div>
            </div>
        </div>
        <div class="right-part">
            <Transition name="fade" mode="out-in">
                <component
                        :is="rightPartData.showElement"
                        :modemsData="rightPartData.modemsData"
                        :key="rightPartData.modemsData.id"
                        @closeMethods="closeRightPart()"
                />
            </Transition>
        </div>
    </div>
</template>

<script>
import { shallowRef } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import ModemPageRightPartForm from '@/components/Simple_elements/ModemPageRightPartForm.vue'

export default {
    name: 'ModemPage',
    props: ['startModemId'],
    computed: {
        ...mapGetters({
            modemList: 'getModemList'
        }),
        workingListModem() {
            return this.modemList.filter((el)=>{
                if (
                    this.createName(el.name).toLowerCase().indexOf(this.searchingLine.toLowerCase()) != -1 ||
                    el.mac_address.toLowerCase().indexOf(this.searchingLine.toLowerCase()) != -1 ||
                    this.searchingLine == ''
                ) {
                    return true;
                } else {
                    return false;
                }
                
            })
        }
    },
    watch: {
        searchingLine(newValue) {

        }
    },
    data() {
        return {
            choosedModemsId: 0,
            searchingLine: '',
            filteredModemList: [],
            rightPartData: {
                showElement: shallowRef(),
                modemsData: {}
            },
        }
    },
    components: {
        ModemPageRightPartForm
    },
    methods: {
        ...mapActions({
            getModems: 'getApiModemsList'
        }),
        createName(name) {
            if (!name) {
                return 'No name'
            } else {
                return name
            }
        },
        showPropertyModem(modemsData) {
            if (this.choosedModemsId == modemsData.id) {
                this.choosedModemsId = 0;
                this.rightPartData.showElement = shallowRef();
                this.rightPartData.modemsData = {};
            } else {
                this.choosedModemsId = modemsData.id;
                this.rightPartData.showElement = shallowRef(ModemPageRightPartForm);
                this.rightPartData.modemsData = modemsData;
            }
        },
        closeRightPart() {
            this.choosedModemsId = 0;
            this.rightPartData.showElement = shallowRef();
            this.rightPartData.modemsData = {};
        }
    },
    created() {
        this.getModems()
        if (this.startModemId) {
            this.modemList.findIndex(el=>{
                if (el.id == this.startModemId) {
                    this.showPropertyModem(el);
                    return true;
                }
            })
        }
    },
    beforeUnmount() {
    }
}
</script>

<style noscoped lang="scss">
.main-dask-modem-page{
    height: 93vh;
    padding: 32px 1vw;
    overflow-y: auto;
    display: flex;
    justify-content: space-between;

    .left-part {
        // background: red;
        height: 85vh;
        width: 47vw;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-track {
            background: none;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: var(--gray-dark-color);
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgb(122, 122, 122);
        }
        .searching-line {
            width: 100%;

            input {
                color: var(--gray-dark-color);
                border-bottom: 1px solid var(--gray-dark-color);
            }
            
        }
        .modem-line {
            
            margin-right: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid var(--gray-dark-color);
            border-radius: 4px;
            padding: 5px 14px;
            transition: all .2s ease;

            &:not(:first-child) {
                margin-top: 12px;
            }

            &:hover {
                background: #94949417;
            }

            &:active {
                background: #9494943a;
            }

            .data-part {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: start;

                p {
                    color: var(--gray-dark-color);
                }
            }
        }
        .modem-line.choosed {
            background: var(--gray-dark-color);

            .data-part {
                p {
                    color: var(--gray-color);
                }
            }
        }
    }

    .right-part {
        // background: gray;
        // height: 10px;
        width: 47vw;

        
    }
}

</style>